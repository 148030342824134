.App {
  text-align: center;
  // height: 90vh;
  height: 100vh;
  overflow: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header{
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #0e00a4;
  width: 95%;
  // overflow: hidden;
  img{
   height: 6vmin;
   width: auto;
   margin-left: 2vmin;
  }
  .burgerDiv{
    margin-right: 2vmin;
    span{
      font-size: 6vmin;
    }
    .options{
      font-family: "Poppins", sans-serif;
      color: #0e00a4;
      font-size: 2vmin;
      text-align: left;
      animation-duration: .3s;
      animation-name: slide;
      // position: absolute;
    }
  }
}

@keyframes slide{
0%{
  margin-left: 50%;
}
100%{
  margin-left: 0;
}
}

.main{
  height: 80%;
  width: 80%;
}

.inputDiv{
  width: 100%;
  input{
    font-size: 2vmin;
    padding: 5px;
    width: 70%; 
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    caret-color: #0e00a4;
  }
  input:focus {
    outline: none;
  }
 

  button{
    width: 20%;
    padding: 0;
    font-family: "Poppins", sans-serif;
    background: none;
    border: none;
    text-align: center;
    span{
      font-size: 4vmin;
    }
  }
}


.component {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.apiDiv{
  flex: 1;
  text-align: left;
  margin-left: 10%;
  max-width: 50%;
  margin-top: 3%;
}

.fillerDiv{
  max-width: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 10%;
}

.fillerText{
  font-family: "Poppins", sans-serif;
  margin: 3vmin;
  color: white;
  text-align: left;
  .cook{
    font-size: 7vmin;
    color: #0e00a4;
    font-family: "Righteous", sans-serif;
  }
  .tell{
    font-size: 5vmin;
    color: #0e00a4;
    font-family: "Righteous", sans-serif;
  }
  .topText{
    font-size: 8vmin;
    color: #0e00a4;

    font-family: "Righteous", sans-serif;
  }
  .midText{
    font-size: 6vmin;
    color: #0e00a4;
    font-family: "Righteous", sans-serif;
  }
  .bottomText{
    font-size: 4.5vmin;
    color: #0e00a4;

    font-family: "Righteous", sans-serif;
  }
  .exampleText{
    font-size: 3vmin;
    color: black;
  }
  .exampleText2{
    font-size: 3vmin;
    color: black;
  }
}

span:hover{
  cursor: default;
}
div:hover{
  cursor: default;
}

.toggle{
  font-family: "Poppins", sans-serif;
  font-size: 2vmin;
  color: #0e00a4;
  font-weight: 600;
  margin-right: 2vmin;
  padding: 0;
}

.buttonDiv{
  margin-top: 4vmin;
  display: flex;
  justify-content: space-between;
  max-width: 50%;
  button{
    background: none;
    border: none;
    text-align: center;
  }
  button:hover{
    opacity: .5;
  }
  span{
    margin: 0;
  }
  span:hover{
    opacity: .5;
  }
}

span:hover{
  opacity: .5;
}


.message {
  font-size: 2vmin;
  font-family: "Poppins", sans-serif;
  margin-top: 2vmin;
}

h1{
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  font-size: 4vmin;
}

.ingredientLi{
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-size: 2vmin;
  margin-top: 1vmin;
  background-color: white;
  border-radius: 18px;
  padding: 18px;
  padding-right: 24px;
  min-width: 25%;
  width: fit-content;
  box-shadow: 0 0 2px 2px rgb(0, 0, 0, .1);

  div{
    color: #0e00a4;
    font-weight: bold; 
    transition: .2s;
    margin-left: 8px;
  }
  div:hover{
    color: rgb(173, 0, 0);
    transition: .1s;
  }
}

.material-symbols-outlined{
  font-size: 3.5vmin;
  margin-right: 4vmin;
  margin-left: 0;
  padding: 0;
  color: #0e00a4;
}

.about{
  display: flex;
  flex-direction: column;
  align-items: center;
  .aboutText{
    width: 80%;
    text-align: left;
    p {
      font-family: "Poppins", sans-serif;
      font-size: 2vmin;
    }
  }
  .aboutLinks{
    margin-top: 2vmin;
    display: flex;
    align-items: center;
    a{
      margin: 2vmin;
      .port{
        height: 9vmin;
         width: auto;
      }
      .linkedin{
        height: 6vmin;
        width: auto
      }
      .git{
        height: 6vmin;
        width: auto
      }
    }
  }
}

.assistantText{
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 0 0 2px 2px rgb(0, 0, 0, .1);
  padding: 18px;
  border-radius: 28px;
  padding-left: 18px;
  width: 68%;
}

// .ingredientList{
//   background-color: white;
//   margin-bottom: 30px;
//   box-shadow: 0 0 2px 2px rgb(0, 0, 0, .1);
//   padding: 18px;
//   border-radius: 28px;
//   padding-left: 18px;
//   width: 50%;
// }

@media(max-width: 800px){
  .burgerDiv{
    margin-right: 2vmin;
    span{
      font-size: 70vmin;
    }
    .options{
      font-family: "Poppins", sans-serif;
      color: #0e00a4;
      font-size: 2vmin;
      text-align: left;
      animation-duration: .3s;
      animation-name: slide;
      // position: absolute;
    }
  }
}